import React from "react";

import { Typography, Sticker } from '..';

import { StyledTransition, CirclePhoto } from "./styled";
import { GeneralContext } from "../../App";

export default function SingleCarouselComponent(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);
    const { review, category } = props;

    return (
        <StyledTransition noOpacity={props.transitioning}>
            <Typography style={{ textAlign: 'left' }}>{review.text}</Typography>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', textAlign: 'left', gap: '2vw', margin: '1vw 0 0 0' }}>
                <CirclePhoto src={review.image} />
                <div>
                    <Typography style={{ fontFamily: 'Amelie', fontSize: hasPhone ? '1.5em' : '30px', color: '#93b6ae', margin: '0 0 0.5vw 0' }}>{review.name}</Typography>
                    {category && <Typography type='caps'>{review.date}</Typography>}
                    {!category && <Typography type='caps'>{review.category}</Typography>}
                </div>
            </div>
        </StyledTransition>
    )
}