import React, { useState } from 'react';

import { Content, InstaImage } from './styled';
import { Typography, Link, ReactIcon } from '..';
import { GeneralContext } from '../../App';

export default function TopFooter(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ data, setData ] = useState([]);
    return (
        <Content>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: hasPhone && '1vw'}}>
                <Typography style={{ fontWeight: 'bold' }}>Goed aangelegd</Typography>
                <Link to='/ouders#aanmelden'>
                    <Typography>Contact als ouder</Typography>
                </Link>
                <Link to='/zorgprofessionals'>
                    <Typography>Contact als zorgprofessional</Typography>
                </Link>
                <Link to='/gemeente'>
                    <Typography>Contact als gemeente</Typography>
                </Link>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: hasTablet ? '2vw' : '1vw', flexWrap: 'wrap', width: hasPhone && '75%'}}>
                <div style={{ display: 'flex', gap: hasPhone ? '3vw' : '1vw', justifyContent: 'center', flexWrap: 'wrap', margin: hasPhone && '0 0 3vw 0'}}>
                    {Array.isArray(data) &&
                     (data.length !== 0 && data.slice(0,4).map((img, i) => {
                        if (img.media_type === 'IMAGE' || img.media_type === 'CAROUSEL_ALBUM') {
                            return <InstaImage key={i} src={img.media_url} onClick={() => window.open(img.permalink, '_blank')}/>
                        } else if (img.media_type === 'VIDEO') {
                            return <InstaImage key={i} src={img.thumbnail_url} onClick={() => window.open(img.permalink, '_blank')}/>
                        }
                        return null;
                    }))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: hasPhone ? '2vw' : hasTablet ? '1vw' : '0.5vw'}}>
                    <ReactIcon name='instagram' />
                    <ReactIcon name='linkedin' />
                    <ReactIcon name='youtube' />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: hasPhone && '1vw'}}>
                <Typography style={{ fontWeight: 'bold' }}>Direct naar</Typography>
                <Link to='/algemene-voorwaarden'>
                    <Typography>Algemene voorwaarden</Typography>
                </Link>
                <Link to='/klachtenregeling'>
                    <Typography>Klachtenregeling</Typography>
                </Link>
                <Link to='/vragen'>
                    <Typography>Veelgestelde vragen</Typography>
                </Link>
            </div>
        </Content>
    )
}