import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3vw 0vw 0 0;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    background-color: white;
    padding: 5vw 0;
    min-height: 50vh;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;