import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { clarity } from 'react-microsoft-clarity';

import { Klachtenregeling, AlgemeneVoorwaarden, OudersEvaluatie, Gratis, Podcast, PodcastOuders, PodcastEpisode, 
  Contact, Home, Lactatiekunde, NotFound, OverOns, Verloskunde, Ervaringen, Medewerking, Vragen, 
  Kosten, ContactFormulier, Temporary, MOM, Kraamzorg, JGZ, Huisarts, 
  Gemeente} from './pages';


import { BottomFooter, TopFooter, Menu, Modal } from './components';
import { Main, Content } from './styled';
import ScrollToTop from "./helpers/ScrollToTop";

import { isPhone, isTablet } from "./helpers/responsive";
import Ouders from "./pages/Ouders/Ouders";

const Zorgprofessionals = lazy(() => import('./pages/Zorgprofessionals/Zorgprofessionals'));

export const GeneralContext = React.createContext();

function App() {
  const [ hasPhone, setHasPhone ] = useState(isPhone(window.screen));
  const [ hasTablet, setHasTablet ] = useState(isTablet(window.screen));

  useLayoutEffect(() => {
    function updateResponsive() {
      setHasPhone(isPhone(window.screen));
      setHasTablet(isTablet(window.screen));
    }
    window.addEventListener('resize', updateResponsive);
    updateResponsive();
    return () => window.removeEventListener('resize', updateResponsive);
  }, []);

  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_ID) 
  })

  return (
    <GeneralContext.Provider value={{ 'hasPhone' : hasPhone, 'hasTablet': hasTablet }}>
      <Router>
        <Main>
          <Menu />
          <Content>
            <ScrollToTop />
            <Suspense fallback={<div>Aan het laden</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path='/ouders' element={<Ouders />} />
                <Route path='/ouders/evaluatie' element={<OudersEvaluatie/>} />
                <Route path='/zorgprofessionals' element={<Zorgprofessionals  />} />
                <Route path='/lactatiekunde' element={<Lactatiekunde  />} />
                <Route path='/verloskunde' element={<Verloskunde  />} />
                <Route path='/kraamzorg' element={<Kraamzorg  />} />
                <Route path='/JGZ' element={<JGZ  />} />
                <Route path='/huisarts' element={<Huisarts  />} />
                <Route path='/gemeente' element={<Gemeente />} />
                <Route path='/over-ons' element={<OverOns  />} />
                <Route path='/podcast' element={<PodcastOuders  />} />
                <Route path='/podcast/ouders' element={<PodcastOuders />} />
                <Route path='/podcast/ouders/:podcastId' element={<PodcastEpisode />} />
                <Route path='/contact' element={<Contact  />} />
                <Route path='/ervaringen' element={<Ervaringen  />} />
                <Route path='/vragen' element={<Temporary  />} />
                <Route path='/medewerking' element={<Medewerking  />} />
                <Route path='/kosten' element={<Kosten />} />
                <Route path='/contact-formulier' element={<ContactFormulier />} />
                <Route path='/algemene-voorwaarden' element={<AlgemeneVoorwaarden />} />
                <Route path='/klachtenregeling' element={<Klachtenregeling />} />
                <Route path='/MOM' element={<MOM />} />
                <Route path='/gratis' element={<Gratis />} />
                <Route path="*" element={<NotFound  />}/>
              </Routes>
            </Suspense>
          </Content>
          <TopFooter  />
          <BottomFooter  />
        </Main>
      </Router>
    </GeneralContext.Provider>
  );
}

export default App;
