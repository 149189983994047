import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3vw 0 0 0;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10vw 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
    }
`;

export const TextContainer = styled.div`
    max-width: 90vw;
    text-align: left;
    margin: 2rem 0 0 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        max-width: 75vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        
    }
`;

export const Team = styled.div`
    padding: 10vw 0;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
    }
`;

export const TeamContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        width: 75vw;
    }
`;

export const TeamImg = styled.img`
    width: 100vw;
    margin: 1rem 0 0 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        width: 90vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        width: 75vw;
    }
`;

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10vw 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    // flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    width: 90vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        gap: 0.5rem;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        width: 75vw;
    }
`;

export const ContactContent = styled.div`
    text-align: left;
    max-width: 90vw;

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        width: 75vw;
    }
`;