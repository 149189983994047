import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../helpers/ScrollToTop';
import { TextField, Typography, Button } from '../../components';
import BrochureModal from '../Lactatiekunde/BrochureModal';
import { Background } from '../Verloskunde/styledOUD';
import { isEmail, isIBCLC } from '../../helpers/form';

import background1 from '../../assets/backgrounds/GA_Branding_Patroon-5a.png';
import { GeneralContext } from '../../App';

export default function ContactFormulier(props) {
    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    const [ openModal, setOpenModal ] = useState(false);

    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ practice, setPractice ] = useState('');
    const [ IBCLC, setIBCLC ] = useState('');
    const [ vraag, setVraag ] = useState('');
    const [ errors, setErrors ] = useState({});

    const query = useQuery();
    const navigate = useNavigate();

    const category = query.get('category');

    useEffect(() => {
        if (!category) {
            navigate('/contact');
        }
    }, [category])

    const sendForm = (event) => {
        event.preventDefault();

        const errors = {};

        if (!isEmail(email)) {
            errors.email = 'Vul een geldig e-mailadres in'
        }

        if (!isIBCLC(IBCLC)) {
            errors.ibclc = 'Vul een geldige IBCLC-code in'
        }

        setErrors(errors);

        // no errors
        if (!Object.keys(errors).length) {
            setErrors({errors})

            // todo: send investering email with "final" number
            alert([email, IBCLC]);
        }
    }

    if (!category) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', overflow: 'hidden' }}>
            {!hasPhone && <Background src={background1} zIndex='1' height='180vw' top='-120%' right='-120%' />}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#f7f3ec', textAlign: 'center', padding: hasPhone ? '7vw 0' : '5vw 0'}}>
                <Typography type='h1' style={{ color: '#145f68', margin: hasPhone ? '0 0 5vw 0' : '0 0 0.5vw 0'}}>Contactformulier</Typography>
                <Typography type='caps' style={{ color: '#145f68', fontSize: '1.3em', margin: hasPhone ? '0 0 7vw 0' : '0 0 2vw 0' }}>{category}n</Typography>
                <Typography style={{ width: hasPhone ? '80%' : '25vw'}}>
                    Plan eenvoudig een vrijblijvend en persoonlijk gesprek in via onderstaande online agenda. Heb je de brochure nog niet ontvangen?
                    Download hem dan <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpenModal(true)}>hier</span>.<br/><br/>
                    {category === 'lactatiekundige' && 'Wil je je aanmelden? Plan dan ook een gesprek in.'}
                </Typography>
            </div>
            <form style={{ backgroundColor: '#fdfcfa', width: '100%', padding: hasPhone ? '10vw 0' : '5vw 0'}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: hasPhone && 'center', gap: hasPhone ? '5vw' : '1vw', width: hasPhone ? '100%' : '50vw', margin: hasPhone ? '0' : '0 0 0 30vw' }}>
                    <TextField 
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        placeholder='Voornaam'
                        required={true}
                        customStyle='secondary'
                    />
                    <TextField 
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        placeholder='Achternaam'
                        required={true}
                        customStyle='secondary'
                    />
                    <TextField 
                        value={practice}
                        onChange={(event) => setPractice(event.target.value)}
                        placeholder='Praktijk'
                        required={true}
                        customStyle='secondary'
                    />
                    {category === 'lactatiekundige' && <TextField 
                        value={IBCLC}
                        onChange={(event) => setIBCLC(event.target.value)}
                        placeholder='IBCLC'
                        required={true}
                        customStyle='secondary'
                        errors={errors.ibclc}
                    />}
                    <TextField 
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder='E-mailadres'
                        email
                        required={true}
                        customStyle='secondary'
                        errors={errors.email}
                    />
                    <TextField 
                        value={vraag}
                        onChange={(event) => setVraag(event.target.value)}
                        placeholder='Vraag of opmerking'
                        required={true}
                        customStyle='secondary'
                        textArea={true}
                    />
                    <Button text='Versturen' type='submit' style={{ margin: '1vw 0 0 0'}} />
                </div>
                
            </form>
            <BrochureModal 
                openModal={openModal}
                setOpenModal={setOpenModal}
                category='lactatiekundige' 
                title='Vraag brochure aan'
                buttonText='Aanvragen'
            />
        </div>
    )
}