import styled from "styled-components";

export const H1 = styled.h1`
    all: unset; /* remove the basic h1 style */
    z-index: 2;
    font-family: Amelie;
    color: var(--bravery);
    font-size: clamp(18px, 2.2em, 46px);

    // -webkit-hyphens: auto;
    // -moz-hyphens: auto;
    // -ms-hyphens: auto;
    // hyphens: auto;

    /* Small phones */
    @media screen and (max-width: 350px){
        font-size: 1.7em;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (min-width: 351px) and (max-width: 766px){
        font-size: 2em;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 4vw;
    }
`;

export const H2 = styled.h2`
    all: unset; /* remove the basic h2 style */
    z-index: 2;
    font-family: Amelie;
    color: var(--trust);
    font-size: 30px;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 25px;
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        font-size: 25px;
    }
`;

export const Caps = styled.div`
    z-index: 2;
    font-family: Gayathri;
    text-transform: uppercase;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 14px;
    }
`;

export const Paragraph = styled.div`
    z-index: 2;
    font-family: Gayathri;
    line-height: 1.3;
    font-size: 1em;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 14px;
    }
`;