import React, { useState } from 'react';
import { Typography, Button, Video, Modal } from '../../components';
import { Container, Introduction, Informatie, Downloadable, Aanmelden, VideoContent } from './styled';
import { Ervaringen } from '../../subpages';

import brochure from '../../assets/documents/brochures/brochure-huisarts.pdf';

export default function Huisarts(props) {
    const [ openModalAanmelden, setModalAanmelden ] = useState(false);
    const [ openModalVragen, setModalVragen ] = useState(false);
    

    const AanmeldenContent = (props) => {
        return (
            <Aanmelden color={props.color}>
                <Button text='Direct aanmelden' onClick={() => setModalAanmelden(true)} style={{ margin: 'auto' }} />
                <Typography>
                    Behoefte aan meer informatie? Stel <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setModalVragen(true)}>hier</span> je vraag.
                </Typography>
            </Aanmelden>
        )
    }

    return (
        <Container>
            <Introduction>
                <Typography type='h1' style={{ color: '#114d55' }}>Huisarts</Typography>
                
            </Introduction>
            <AanmeldenContent />
            <Informatie>
                <Downloadable>
                    <Typography type='h2'>Brochure centering feeding</Typography>
                    <Button href={brochure} text='Download de brochure' buttonstyle='secondary' style={{ margin: 'auto' }} target='_blank' download='brochure-Goed-Aangelegd.pdf'  />
                </Downloadable>
                {/* <Downloadable>
                    <Typography type='h2'>Stappenplan samenwerking</Typography>
                    <Button href={stappenplan} text='Download het stappenplan' buttonstyle='secondary' style={{ margin: 'auto' }} target='_blank' download='stappenplan-samenwerking-Goed-Aangelegd.pdf'  />
                </Downloadable> */}
            </Informatie>
            <VideoContent>
                <Typography type='h2' style={{ color: '#032D34' }}>Introductie Goed Aangelegd</Typography>
                <Video videoId='RDqENFc32aA' width={'45vw'} />
            </VideoContent>
            <AanmeldenContent color='F7F3EC' />
            <Ervaringen />
            <ModalAanmelden
                open={openModalAanmelden}
                onClose={() => setModalAanmelden(false)}
            />
            <ModalVragen
                open={openModalVragen}
                onClose={() => setModalVragen(false)}
            />
        </Container>
    )
}

export const ModalAanmelden = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <Typography type='h2' as='h3'>Aanmelden</Typography>
            <br/>
            <Typography>
                Wat fijn dat je interesse hebt in Centering Feeding. 
                Momenteel werken we aan verschillende onderdelen van onze website, waaronder de automatisering achter de knop ‘Aanmelden’. 
                We willen je daarom vragen je aanmelding te mailen naar <a href='mailto:info@goedaangelegd.nl'>info@goedaangelegd.nl</a>.
            </Typography>
        </Modal>
    )
}

export const ModalVragen = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <Typography type='h2' as='h3'>Vragen</Typography>
            <br/>
            <Typography>
                Wat fijn dat je interesse hebt in Centering Feeding. 
                Momenteel werken we aan verschillende onderdelen van onze website, waaronder de automatisering achter de knop ‘Vragen’. 
                We willen je daarom vragen om je vraag per mail in te sturen naar <a href='mailto:info@goedaangelegd.nl'>info@goedaangelegd.nl</a>.
            </Typography>
        </Modal>
    )
}