import React from 'react';
import { Typography, Button } from '../../components';

import { Container, Introduction } from './styled';

export default function Gratis(props) {

    return (
        <Container>
            <Introduction>
                <Typography type='h1' style={{ color: '#114d55', margin: '0 0 3vw 0' }}>Gratis weggever</Typography>
                <Typography type='caps' style={{ color: '#B2522D'}}>DOWNLOAD HIER GRATIS DE SUCCESVOLLE BORSTVOEDINGSCHECKLIST</Typography>
                <Button 
                    onClick={() => window.open('https://mailchi.mp/f555bfdde75b/gratis-checklist-succesvolle-borstvoeding', '_blank')}
                    text='YES, DEZE WIL IK!'
                />
            </Introduction>
        </Container>
    )
}