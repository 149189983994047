import { List, ListItem, Typography, Button } from '../../components';
import { Container, Introduction, TextContainer, Team, TeamContainer, TeamImg, Contact, ButtonGroup, ContactContent } from './styled';

import teamleden from '../../assets/images/over/teamleden.png';

export default function OverOns(props) {
    return (
        <Container>
            <Introduction>
                <Typography type='h1' style={{ color: '#92330F' }}>Goed Aangelegd</Typography>
                <TextContainer>
                    <Typography type='caps' style={{ fontWeight: 800, color: '#145F68' }}>Als je aan de verzorging van een pasgeboren baby denkt…</Typography>
                    <Typography>Wat komt er in je op? Waarschijnlijk het voeden van je baby - een basisbehoefte. Toch blijkt dat juist dit vanzelfsprekende aspect vaak uitdagingen met zich meebrengt, vooral in de eerste weken na de geboorte.</Typography>
                </TextContainer>
                <TextContainer>
                    <Typography type='caps' style={{ fontWeight: 800, color: '#145F68' }}>Het probleem dat ons wakker hield</Typography>
                    <Typography>Veel ouders ervaren onzekerheid over hoe, wat, en wanneer ze hun baby moeten voeden. Borstvoeding, flesvoeding, of een combinatie: het proces kan ingewikkeld zijn, en hulp is niet altijd direct beschikbaar. Voor zorgprofessionals is het een uitdaging om ouders continu te begeleiden en op het juiste moment de juiste informatie te geven.
                    </Typography>
                </TextContainer>
                <TextContainer>
                    <Typography type='caps' style={{ fontWeight: 800, color: '#145F68' }}>Onze droom voor de toekomst</Typography>
                    <Typography>Wij willen dat elke ouder met vertrouwen hun baby kan voeden, gesteund door toegankelijke, wetenschappelijk onderbouwde en praktische begeleiding. Onze oplossing brengt ouders en zorgprofessionals dichter bij elkaar door middel van een gebruiksvriendelijke dienstverlening met persoonlijke ondersteuning.
                    </Typography>
                </TextContainer>
            </Introduction>
            <Team>
                <Typography type='h2' style={{ textAlign: 'center', color: '#37686E' }}>Het team achter Goed Aangelegd</Typography>
                <TeamContainer>
                    <Typography>We zijn een bonte verzameling van creatieve, bevlogen professionals met één missie: de zorg rondom (borst)voeding vernieuwen en verbeteren. Leer ons kennen.</Typography>
                    <TeamImg src={teamleden} />
                </TeamContainer>
            </Team>
            <Contact>
                <Typography type='h2' style={{ textAlign: 'center' }}>Hoe we jou helpen</Typography><br/><br/>
                <ContactContent>
                    <Typography>
                        Met Centering Feeding bieden we:
                    </Typography><br/>
                    <List>
                        <ListItem>
                            <Typography>
                                <span style={{ fontWeight: 800 }}>Voor ouders:</span> Directe begeleiding en praktische tips op maat, zodat je weet wat je kunt doen voor die fijne voedingservaringen.
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography>
                                <span style={{ fontWeight: 800 }}>Voor zorgprofessionals:</span> Handvatten en begeleiding om ouders beter te ondersteunen, zonder extra werkdruk.
                            </Typography>
                        </ListItem>
                    </List><br/><br/>
                    <Typography style={{ textAlign: 'left' }}>Klaar om samen te gaan voor dat geslaagde voedingsavontuur? Klik op de groep waartoe jij behoort en ontdek wat wij voor jou kunnen betekenen.</Typography>
                </ContactContent><br/>
                <ButtonGroup>
                    <Button text='Ouders' hashTo='/ouders' buttonstyle='fourthly' />
                    <Button text='Lactatiekunde' hashTo='/lactatiekunde' buttonstyle='fourthly' />
                    <Button text='Verloskunde' hashTo='/verloskunde' buttonstyle='fourthly' />
                    <Button text='Kraamzorg' hashTo='/kraamzorg' buttonstyle='fourthly' />
                    <Button text='JGZ' hashTo='/jgz' buttonstyle='fourthly' />
                    <Button text='Huisarts' hashTo='/huisarts' buttonstyle='fourthly' />
                    <Button text='Gemeenten' hashTo='/gemeente' buttonstyle='fourthly' />
                </ButtonGroup>
                
            </Contact>
        </Container>
    )
}