import React from "react";
import { Button, Typography, TitlePage } from "../../components";
import { Container, Background } from "./styled";
import { scrollIntoView } from "../../helpers/ScrollToTop";

import benieuwd from '../../assets/backgrounds/benieuwd.png';
import benieuwd1 from '../../assets/backgrounds/benieuwd1.png';
import benieuwd2 from '../../assets/backgrounds/benieuwd2.png';

import { GeneralContext } from "../../App";

export default function Benieuwd(props) {
    const { givenRef, category, onClickRef, fullBackgroundHeight } = props;

    const { hasPhone, hasTablet } = React.useContext(GeneralContext);

    let text = 'ONTDEK WAT GOED AANGELEGD JOU KAN BIEDEN';
    if (category === 'lactatiekundige') {
        text = 'Plan dan eenvoudig een gesprek in voor al JE vragen, eventuele twijfels en aanmelding'
    } else if (category === 'ouders') {
        text = 'NEEM DAN CONTACT OP MET EEN AANGESLOTEN LACTATIEKUNDIGE'
    } else if (category === 'verloskundige') {
        text = 'Plan eenvoudig een persoonlijk gesprek in via onderstaande online agenda.'
    }

    const onClick = (e) => {
        if (category === 'lactatiekundige') {
            // navigate('/contact-formulier?category=lactatiekundige');
            window.location.href = 'https://calendly.com/goed-aangelegd/zorgprofessionals'; e.preventDefault();
        } else if (category === 'ouders') {
            scrollIntoView(onClickRef);
        } else if (category === 'verloskundige') {
            // navigate('/contact-formulier?category=verloskundige');
            window.location.href = 'https://calendly.com/goed-aangelegd/zorgprofessionals'; e.preventDefault();
        }
    }

    const PageContact = (text, onClick) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <Typography type='caps' style={{ color: '#b04f29', fontSize: '1em'}}>{text}</Typography>
                <Button 
                    buttonstyle='fourthly'
                    text='Contact'
                    onClick={onClick}
                    style={{ margin: hasPhone ? '5vw 0' : '1vw 0'}}
                />
            </div>
        )
    }

    const HomeContact = () => {
        return (
            <div style={{ zIndex: '3', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <Typography type='caps' style={{ color: '#b04f29', width: hasPhone ? '75%' : hasTablet ? '70%' : '50%', fontSize: '1.2em', margin: hasPhone ? '0 0 5vw 0' : '0 0 1vw 0' }}>Ontdek wat goed aangelegd jou kan bieden</Typography>
                <Typography style={{ maxWidth: hasPhone && '75vw' }}>Je kunt ook via deze pagina's contact opnemen.</Typography>
                <div style={{ display: 'flex', gap: hasPhone ? '2vw' : hasTablet ? '2vw' : '1vw', justifyContent: 'center', width: hasPhone ? '100%' : '50%', alignContent: 'center', flexWrap: 'wrap', margin: hasPhone ? '2vw 0 5vw 0' : hasTablet ? '1vw 0 0 0' : 'auto'}}>
                    <Button 
                        buttonstyle='fourthly'
                        text='Ouders'
                        to='/ouders#aanmelden'
                        style={{ margin: '1vw 0', width: hasPhone ? '150px' : '8vw' }}
                    />
                    <Button 
                        buttonstyle='fourthly'
                        text='Lactatiekundige'
                        to='/lactatiekunde'
                        style={{ margin: '1vw 0', width: hasPhone ? '150px' : '8vw'}}
                    />
                    <Button 
                        buttonstyle='fourthly'
                        text='Verloskundige'
                        to='/verloskunde'
                        style={{ margin: '1vw 0', width: hasPhone ? '150px' : '8vw'}}
                    />
                    
                </div>
            </div>
        )
    }

    return (
        <Container ref={givenRef} style={{ width: !category && '100%'}}>
            <TitlePage 
                color='#92330f'
                text='Contact'
            />
            {!hasPhone && fullBackgroundHeight && <Background src={benieuwd} height={fullBackgroundHeight} bottom='10%' left='0' zIndex='1' style={{ maxWidth: '30vw' }} />}
            {!hasPhone && !fullBackgroundHeight && givenRef.current && <Background src={benieuwd1} height={givenRef.current?.clientHeight*0.8} top='0' left={hasTablet ? '-45%' : '0'} zIndex='1' />}
            {!hasPhone && givenRef.current && <Background src={benieuwd2} width={givenRef.current?.clientWidth*0.3 || '0'} height={givenRef.current?.clientHeight*0.8 || '0'} bottom={hasTablet ? '-50%' : '0'} right={hasTablet ? '-80%' : '0'} zIndex='2'/>}
            <Typography type='h2' style={{ color: '#b04f29', fontSize: '1.8em', margin: hasPhone ? '0 0 5vw 0' : hasTablet ? '0 0 2vw 0' : '0 0 1vw 0' }}>Benieuwd?</Typography>
            {category ?
                PageContact(text, onClick) : HomeContact()
                
            }
            
        </Container>
    )
}