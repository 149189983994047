import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5vw 0vw 10vw 0;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    background-color: #f7f3ec;
    min-height: 40vh;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;

export const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4vw;
    margin: 0 15vw;
    
    background-color: #f7f3ec;

    text-align: center;

    /* Tablet: height */
    @media (min-width: 641px)  {
        // flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 5vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 1vw;
    }
`

export const ZorgProfTitle = styled.div`
    color: white;
    text-transform: uppercase;
    background-color: #114D55;
    
    padding: 0.4em 0.2em 0.2em 0.2em;

    /* Tablet: height */
    @media (min-width: 641px)  {
    }

    /* Desktop: min-height */
    @media (min-width: 1025px) {
        font-size: 1.5em;
    }
`;

export const GridTile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    text-decoration: none;
    width: 33vw;

    &:hover ${ZorgProfTitle} {
        background-color: white;
        color: #114D55;
    }

    /* Tablet: height */
    @media (min-width: 641px)  {
        width: 20vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        width: 15vw;
    }
`;

export const ZorgProfImg = styled.img`
    width: 100%;
`;

export const Background = styled.img`
    position: ${props => props.position || 'absolute'};
    z-index: ${props => props.zIndex || 1};

    width: ${props => props.width || 'default'};
    height: ${props => props.height || 'default'};

    top: ${props => props.top || 'default'};
    left: ${props => props.left || 'default'};
    bottom: ${props => props.bottom || 'default'};
    right: ${props => props.right || 'default'};

    margin: ${props => props.margin || '0'};

    transform: ${props => props.rotateDeg ? `rotate(${props.rotateDeg}deg)` : 'default'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        display: none;
    }
`;