import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Introduction = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3vw 0vw 0 0;
    text-align: center;
    z-index: inherit;
    justify-content: center;
    background-color: white;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 15vw 0;
    }
`;

export const Aanmelden = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5vw;

    text-align: center;

    background-color: ${props => props.color || 'white'};
    width: 100%;

    padding: 10vw 0;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        gap: 5vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 3vw;
    }
`

export const Informatie = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10vw;

    width: 100%;

    padding: 10vw 0vw;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        gap: 3vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 2vw;
    }
`;

export const Downloadable = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3vw;

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 2vw;
    }
`;

export const VideoContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 5vw;

    padding: 10vw 0;
    background-color: #F1E3D4;

    /* Tablet: height */
    @media (min-width: 641px)  {
        padding: 5vw 0;
        gap: 3vw;
    }

    /* Desktop: min-height */
    @media (min-width: 1024px) {
        gap: 2vw;
    }
`;