import React from 'react';
import { Container, Introduction, GridContainer, GridTile, ZorgProfImg, ZorgProfTitle, Background } from './styled';
import { Typography } from '../../components';
import { Link } from 'react-router-dom';

// import lactatiekundeImg from '../../assets/images/illustrations/lactatiekunde.png';
// import verloskundeImg from '../../assets/images/illustrations/verloskunde.png';
// import kraamzorgImg from '../../assets/images/illustrations/kraamzorg.png';
// import JGZImg from '../../assets/images/illustrations/jgz.png';
// import huisartsImg from '../../assets/images/illustrations/huisarts.png';

import background1 from '../../assets/backgrounds/lactatiekundigen1.png';

const loadImages = require.context('../../assets/images/illustrations', true);

export default function Zorgprofessionals(props) {

    const IconGrid = () => {
        const illustrationList = [
            { name: 'lactatiekunde' },
            { name: 'verloskunde' },
            { name: 'kraamzorg' },
            { name: 'JGZ' },
            { name: 'huisarts' },
        ]

        return (
            <GridContainer>
                {illustrationList.map((item, i) => {
                    return (
                        <GridTile as={Link} to={`/${item.name}`} key={i}>
                            <ZorgProfTitle>{item.name}</ZorgProfTitle>
                            <ZorgProfImg src={loadImages(`./${item.name.toLocaleLowerCase()}.jpg`)} alt={item.name} loading='lazy' />
                        </GridTile>
                    )
                })}
            </GridContainer>
        )
    }

    

    return (
        <Container>
            <Background width='18vw' src={background1} zIndex='3' top='0' left='100' />
            <Introduction>
                <Typography type='h1' style={{ color: '#114d55' }}>
                    Onze borstvoedingszorg <br/>geeft het vertrouwen<br/> aan ouders
                </Typography>
                <Typography style={{ color: '#114d55', margin: '5em 0 1em 0' }}>Klik op de groep waartoe jij behoort</Typography>
                <IconGrid />
            </Introduction>
        </Container>
    )
}