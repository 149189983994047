import React, { useState } from "react"
import { Typography, Sticker, Image } from "../../components";
import { styled } from '@mui/material/styles';
import { Slider, SliderThumb } from "@mui/material";
import { Container, Tijdsinvestering, Winst, Introduction, SliderMark } from "./styled";

import lactatiekundigen from '../../assets/images/sticker-lactatiekundigen.png';
import { HashLink } from "react-router-hash-link";

import kosten1 from '../../assets/images/kosten/kosten1.png';
import kosten2 from '../../assets/images/kosten/kosten2.png';
import slider from '../../assets/images/kosten/slider.png';

import { GeneralContext } from '../../App';

const CustomSlider = styled(Slider)({
    color: '#e7cdb5',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 0,
      width: 24,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
  });

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <SliderMark src={slider} />
        </SliderThumb>
    );
}

export default function Kosten(props) {
    const { hasPhone } = React.useContext(GeneralContext);
    const [ numWorkshops, setNumWorkshops ] = useState(4);

    const workshopsRange = [...Array(12).keys()];
    const sliderMarks = workshopsRange.map((w, i) => ({ label: (w+1).toString(), value: (w+1)}));

    const calculateTime = () => {
        const hoursPerYear = numWorkshops * 4 * 14;
        const hoursPerWeek = (hoursPerYear / 52).toString();

        let hoursReturn = hoursPerWeek;
        let minutesReturn = null;
        if (hoursPerWeek.includes('.')) {
            hoursReturn = hoursReturn.split('.')[0];
            const minutesDecimal = Number('0.' + hoursPerWeek.split('.')[1]);
            minutesReturn = (minutesDecimal * 60).toString();
            if (minutesReturn.includes('.')) {
                minutesReturn = minutesReturn.split('.')[0];
            }
        }

        let finalString = '';
        if (hoursReturn > 0) {
            finalString = hoursReturn + ' uur'
            if (minutesReturn) {
                finalString += ' en ' + minutesReturn + ' minuten '
            }
        } else {
            finalString += minutesReturn + ' minuten '
        }

        return finalString;
    }

    const calculateHourlyRate = () => {
        const totalIncome = numWorkshops * 3440 - 600 - numWorkshops * 800;
        const hoursPerYear = numWorkshops * 4 * 14;
        return Math.round(totalIncome / hoursPerYear * 100) / 100;
    }


    return (
        <Container>
            <HashLink to='/lactatiekundigen#enthousiast' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })} style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 10}}>
                <Sticker src={lactatiekundigen} position='fixed' top='80vh' width='10vw' hover={true} />
            </HashLink>
            <Introduction>
                <Typography type='h1' style={{ color: '#145F68', textAlign: 'center', fontSize: '1.6em', margin: '2vw 0' }}>Kosten en opbrengsten</Typography>
                <Typography style={{ margin: '0 0 3vw 0' }}>Het financiële plaatje wordt mede bepaald door het aantal workshopreeksen dat jaarlijks wordt gegeven.</Typography>
            </Introduction>
            <Tijdsinvestering>
                <Typography type='caps' style={{ fontSize: '1.2em', color: '#145F68' }}>Tijdsinvestering</Typography>
                <Typography>Één workshopreeks omvat de volgende werkuren:</Typography>
                <Image src={kosten1} style={{ width: hasPhone ? '100%' : '80%', height: 'auto'}} />
                <Typography>
                    Je bepaalt zelf het aantal reeksen dat jij per jaar start.
                    Het streven is om 4 tot 6 koppels met een vergelijkbare uitgerekende datum in te delen in één groep.
                    Je bent zelf verantwoordelijk voor het plannen van de workshops.
                </Typography>
                <Typography>
                    Een jaarrooster kan er bijvoorbeeld als volgt uitzien,
                    waarbij om de maand een nieuwe reeks van start gaat.
                    Dit komt neer op een begeleiding van 30 koppels per jaar (6 reeksen x ca. 5 koppels).
                </Typography>
                <Image src={kosten2} style={{ width: hasPhone ? '100%' : '80%', height: 'auto'}} />
            </Tijdsinvestering>
            <Winst>
                <Typography type='h2' style={{ fontSize: '1.8em', color: '#104D55', margin: '1vw 0'}}>Bereken mijn investering en winst</Typography>
                <Typography>
                    De kosten bestaan uit eenmalige (training en workshopspakket) en doorlopende investeringen (cursusboeken en abonnement).
                    De aanmeldkosten die de koppels betalen, zijn volledig voor jou.
                    Afhankelijk van de zorgverzekering worden de kosten voor de cursisten volledig vergoed.
                </Typography>
                <Typography>
                    Het financiële plaatje wordt mede bepaald door het aantal workshopsreeksen dat jaarlijks wordt gegegeven.
                    Vul je gewenste ureninvestering in en reken eenvoudig uit wat jouw tijdsinvestering en winstmarge per jaar gaat zijn:
                </Typography>
                <Typography type='caps' style={{ fontSize: '1em', color: '#B04F29', margin: '2vw 0 0 0' }}>Aantal workshops per maand</Typography>
                <CustomSlider
                    value={numWorkshops}
                    slots={{ thumb: AirbnbThumbComponent }}
                    min={1}
                    max={12}
                    step={1}
                    marks={sliderMarks}
                    style={{ width: hasPhone ? '100%' : '80%'}}
                    onChange={(e, v) => setNumWorkshops(v)}
                />      
                <Typography>Dit komt neer op een totaalinvestering van <span style={{ fontWeight: '600' }}>{calculateTime()}</span>
                 per week met een uurtarief van <span style={{ fontWeight: '600' }}>€{calculateHourlyRate()},-</span></Typography>
            </Winst>
        </Container>
    )
}